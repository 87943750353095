<template>
  <div>
    <div class="home">
      <img class="home_background" src="@/assets/banner-checkout.jpg">
    </div>

    <div class="checkout">
      <div class="section_container">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="checkout_container d-flex flex-xxl-row flex-column align-items-start justify-content-start">

                <div class="billing checkout_box">
                  <div class="checkout_title">Billing Address</div>
                  <div class="checkout_form_container">
                    <form @submit.prevent="" class="checkout_form">
                      <div class="row">
                        <div class="col-lg-6">
                          <label for="checkout_name">First Name*</label>
                          <input
                            type="text"
                            name="first_name"
                            id="checkout_name"
                            class="checkout_input"
                            v-model="form.first_name"
                            v-validate.immediate="'required'"
                          >
                          <div v-show="fields.first_name && fields.first_name.dirty">
                            <div
                              class="text-danger"
                              v-for="error in errors.collect('first_name')"
                              :key="error"
                            >
                              <small>{{ error }}</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <label for="checkout_last_name">Last Name*</label>
                          <input
                            type="text"
                            name="last_name"
                            id="checkout_last_name"
                            class="checkout_input"
                            v-model="form.last_name"
                            v-validate.immediate="'required'"
                          >
                          <div v-show="fields.last_name && fields.last_name.dirty">
                            <div
                              class="text-danger"
                              v-for="error in errors.collect('last_name')"
                              :key="error"
                            >
                              <small>{{ error }}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label for="checkout_address">Address*</label>
                        <input
                          type="text"
                          name="address"
                          id="checkout_address"
                          class="checkout_input"
                          v-model="form.address"
                          v-validate.immediate="'required'"
                        >
                        <div v-show="fields.address && fields.address.dirty">
                          <div
                            class="text-danger"
                            v-for="error in errors.collect('address')"
                            :key="error"
                          >
                            <small>{{ error }}</small>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label for="checkout_city">City/Town*</label>
                        <input
                          type="text"
                          name="city"
                          id="checkout_city"
                          class="checkout_input checkout_input"
                          v-model="form.city"
                          v-validate.immediate="'required'"
                        >
                        <div v-show="fields.city && fields.city.dirty">
                          <div
                            class="text-danger"
                            v-for="error in errors.collect('city')"
                            :key="error"
                          >
                            <small>{{ error }}</small>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label for="checkout_state">State*</label>
                        <input
                          type="text"
                          name="state"
                          id="checkout_state"
                          class="checkout_input"
                          v-model="form.state"
                          v-validate.immediate="'required'"
                        >
                        <div v-show="fields.state && fields.state.dirty">
                          <div
                            class="text-danger"
                            v-for="error in errors.collect('state')"
                            :key="error"
                          >
                            <small>{{ error }}</small>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label>Shipping Method*</label>
                        <div>
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              name="provider"
                              id="provider-ems"
                              class="custom-control-input"
                              value="ems"
                              v-model="form.provider"
                            >
                            <label
                              for="provider-ems"
                              class="custom-control-label"
                            >
                              EMS (Trackable. 3-5 working days)
                            </label>
                          </div>
                          <div
                            class="custom-control custom-radio"
                            v-if="air_mail.length && cartWeight <= 2000"
                          >
                            <input
                              type="radio"
                              name="provider"
                              id="provider-airmail"
                              class="custom-control-input"
                              value="air_mail"
                              v-model="form.provider"
                            >
                            <label
                              for="provider-airmail"
                              class="custom-control-label"
                            >
                              Airmail (Non-trackable, 10-14 working days)
                            </label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label for="checkout_country">Country*</label>
                        <select
                          name="country"
                          id="checkout_country"
                          class="dropdown_item_select checkout_input"
                          v-model="form.country"
                          v-validate.immediate="'required'"
                        >
                          <option value="">Select Country</option>
                          <option
                            v-for="(method, m) of shippingMethod"
                            :value="method.country_code"
                            :key="m"
                          >
                            {{ method.country_name }}
                          </option>
                        </select>
                        <div v-show="fields.country && fields.country.dirty">
                          <div
                            class="text-danger"
                            v-for="error in errors.collect('country')"
                            :key="error"
                          >
                            <small>{{ error }}</small>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label for="checkout_zipcode">Zipcode*</label>
                        <input
                          type="text"
                          name="zipcode"
                          id="checkout_zipcode"
                          class="checkout_input"
                          v-model="form.zipcode"
                          v-validate.immediate="'required'"
                        >
                        <div v-show="fields.zipcode && fields.zipcode.dirty">
                          <div
                            class="text-danger"
                            v-for="error in errors.collect('zipcode')"
                            :key="error"
                          >
                            <small>{{ error }}</small>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label for="checkout_email">Email Address*</label>
                        <input
                          type="email"
                          name="email"
                          id="checkout_email"
                          class="checkout_input"
                          v-model="form.email"
                          v-validate.immediate="'required|email'"
                        >
                        <div v-show="fields.email && fields.email.dirty">
                          <div
                            class="text-danger"
                            v-for="error in errors.collect('email')"
                            :key="error"
                          >
                            <small>{{ error }}</small>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label for="checkout_phone">Phone no*</label>
                        <input
                          type="phone"
                          name="phone"
                          id="checkout_phone"
                          class="checkout_input"
                          v-model="form.phone"
                          v-validate.immediate="'required'"
                        >
                        <div v-show="fields.phone && fields.phone.dirty">
                          <div
                            class="text-danger"
                            v-for="error in errors.collect('phone')"
                            :key="error"
                          >
                            <small>{{ error }}</small>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div class="cart_total">
                  <div class="cart_total_inner checkout_box">
                    <div class="checkout_title">Cart total</div>
                    <ul class="cart_extra_total_list">
                      <li class="d-flex flex-row align-items-center justify-content-start">
                        <div class="cart_extra_total_title">Subtotal</div>
                        <div class="cart_extra_total_value ml-auto">${{ cartPrice }}</div>
                      </li>
                      <li class="d-flex flex-row align-items-center justify-content-start" v-if="voucherAmount">
                        <div class="cart_extra_total_title">Discount</div>
                        <div class="cart_extra_total_value ml-auto">-${{ voucherAmount }}</div>
                      </li>
                      <li class="d-flex flex-row align-items-center justify-content-start">
                        <div class="cart_extra_total_title">Shipping</div>
                        <div class="cart_extra_total_value ml-auto">{{ shippingPriceText }}</div>
                      </li>
                      <li class="d-flex flex-row align-items-center justify-content-start">
                        <div class="cart_extra_total_title">Total</div>
                        <div class="cart_extra_total_value ml-auto">${{ (cartPrice - voucherAmount + shippingPrice).toFixed(2) }}</div>
                      </li>
                    </ul>

                    <form @submit.prevent="setVoucher" class="coupon_form">
                      <input
                        type="text"
                        class="coupon_input"
                        placeholder="Gift card or discount code"
                        v-model="voucher"
                        required
                      >
                      <button
                        type="submit"
                        class="coupon_button"
                        v-if="!form.voucher_identifier && isVoucherEnabled"
                      >
                        apply code
                      </button>
                      <button
                        type="button"
                        class="coupon_button"
                        @click.prevent.stop="removeVoucher"
                        v-else-if="form.voucher_identifier && isVoucherEnabled"
                      >
                        remove code
                      </button>
											<div class="mt-2 text-danger">
												<small>{{ voucherError }}</small>
											</div>
                    </form>

                    <div class="mt-5 text-center">
                      By placing your order, you agree to Brilcrist's store <router-link to="/terms-and-conditions" target="_blank">terms and conditions of use</router-link>.
                    </div>
                    <div
                      id="paypal-button-container"
                      class="mt-3 text-center"
                      v-show="isFormValid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Noty from 'noty';

export default {
  name: 'checkout',

  data: () => ({
    ems: [],
    air_mail: [],
    voucher: '',
    voucherAmount: 0,
		voucherError: '',
    form: {
      first_name: '',
      last_name: '',
      address: '',
      city: '',
      state: '',
      country: '',
      zipcode: '',
      email: '',
      phone: '',
      voucher_identifier: '',
      provider: null,
    },
  }),

  computed: {
    cart() {
      return this.$store.state.cart;
    },
    cartPrice() {
      return this.$store.state.cartPrice;
    },
    cartWeight() {
      return this.$store.state.cartWeight;
    },
    overWeight() {
      return this.cartWeight > this.maxSelectedCountryWeight
        ? this.cartWeight - this.maxSelectedCountryWeight
        : 0;
    },
    selectedCountry() {
      return this.shippingMethod && this.shippingMethod.find(m => m.country_code === this.form.country);
    },
    maxSelectedCountryWeight() {
      return this.selectedCountry
        ? Math.max(
          ...Object.keys(this.selectedCountry.cost_in_usd)
            .map(i => parseInt(i))
            .filter(i => !isNaN(i))
        )
        : 0;
    },
    overWeightCost() {
      return this.selectedCountry && this.overWeight && this.selectedCountry.cost_in_usd.next
        ? this.overWeight / 100 * this.selectedCountry.cost_in_usd.next
        : 0;
    },
    baseWeightPrice() {
      const base = this.overWeight
        ? this.maxSelectedCountryWeight
        : this.cartWeight;
      return this.selectedCountry
        ? this.selectedCountry.cost_in_usd[
          Object.keys(this.selectedCountry.cost_in_usd).find(k => base <= k)
        ]
        : 0;
    },
    shippingPrice() {
      return this.selectedCountry
        ? this.baseWeightPrice + this.overWeightCost
        : 0;
    },
    shippingPriceText() {
      return this.shippingPrice
        ? `$${this.shippingPrice}`
        : 'Free';
    },
    isFormValid() {
      return !this.errors.items.length;
    },
    shippingMethod() {
      return this[this.form.provider];
    },
    isVoucherEnabled() {
      return !!this.form.email && !this.errors.has('email');
    },
  },

  watch: {
    'form.provider'() {
      this.form.country = this.$options.data().form.country;
    },
  },

  created() {
    this.fetchEms();
    this.fetchAirmail();
  },

  mounted() {
    this.form.provider = 'ems';
    this.createPaypalButton();
  },

  methods: {
    async fetchEms() {
      const { data } = await this.$api.shippingMethods('ems');
      this.ems = data.data.sort((a, b) => a.country_name < b.country_name
        ? -1
        : a.country_name > b.country_name
        ? 1
        : 0
      );
    },
    async fetchAirmail() {
      const { data } = await this.$api.shippingMethods('air_mail');
      this.air_mail = data.data.sort((a, b) => a.country_name < b.country_name
        ? -1
        : a.country_name > b.country_name
        ? 1
        : 0
      );
    },
    async setVoucher() {
      if (!this.isVoucherEnabled) return;
      const { data } = await this.$api.voucher({
        code: this.voucher,
        email: this.form.email,
        items: this.cart.map(i => ({
          sku: i.sku,
          quantity: i.qty,
        })),
      });
      if (data.identifier) {
        this.form.voucher_identifier = data.identifier;
        this.voucherAmount = data.amount;
				this.voucherError = this.$options.data().voucherError;
			} else if (data.detail) {
        this.form.voucher_identifier = this.$options.data().form.voucher_identifier;
        this.voucherAmount = this.$options.data().voucherAmount;
				this.voucherError = data.detail;
			}
    },
    removeVoucher() {
      const {
        voucher,
        voucherAmount,
        voucherError,
        form,
      } = this.$options.data();
      this.voucher = voucher;
      this.voucherAmount = voucherAmount;
      this.voucherError = voucherError;
      this.form.voucher_identifier = form.voucher_identifier;
    },
    createPaypalButton() {
      // Render the PayPal button into #paypal-button-container
      /* eslint-disable no-undef */
      paypal.Buttons({
        // Set up the transaction
        createOrder: () => fetch(`${process.env.BACKEND}/order/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: this.form.email,
            phone: this.form.phone,
            name: `${this.form.first_name} ${this.form.last_name}`,
            voucher_identifier: this.form.voucher_identifier,
            shipping: {
              address: this.form.address,
              city: this.form.city,
              state: this.form.state,
              postal_code: this.form.zipcode,
              country_code: this.form.country,
              provider: this.form.provider,
            },
            items: this.cart.map(i => ({
              sku: i.sku,
              quantity: i.qty,
            })),
          })})
          .then(res => res.json())
          .then(data => data.id),
        // Finalize the transaction
        onApprove: data => fetch(`${process.env.BACKEND}/order/${data.orderID}/capture/`, {
            method: 'POST',
          })
          .then(res => res.json())
          .then(details => {
            new Noty({
              text: `Thank you for purchasing ${details.payer.name.given_name}`,
              timeout: 5000,
              type: 'success',
            }).show();
            this.$router.push(`/order/${details.token}/detail`);
            this.$store.commit('CLEAR_CART');
          }),
      }).render('#paypal-button-container');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/views/checkout.scss';
</style>
